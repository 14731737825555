import type { BreadcrumbItemT } from '../ui/breadcrumb';
import type { NavigationNode, NavigationNodesMap } from './navigation-utils';

const defaultItem: BreadcrumbItemT = {
  href: '/catalogue',
  title: 'Browse APIs',
};

const parseNavItemSlug = (
  item: string,
  currentNavNode: NavigationNode,
  navNodes: NavigationNodesMap,
): BreadcrumbItemT => {
  const itemPosition = currentNavNode.slug.indexOf(item);
  const nodeKey = currentNavNode.slug.slice(0, itemPosition + item.length);
  const node = navNodes.get(nodeKey);

  return { href: `/${node?.slug ?? ''}`, title: node?.title ?? 'Home' };
};

const createItems = (currentNavNode: NavigationNode, navNodes: NavigationNodesMap): BreadcrumbItemT[] => {
  const slugParts = currentNavNode.slug.split('/');

  if (slugParts.length === 1) return [];

  return slugParts.map((part) => parseNavItemSlug(part, currentNavNode, navNodes));
};

export const navigationBreadcrumbParse = (
  navNodes: NavigationNodesMap,
  location: Window['location'],
): BreadcrumbItemT[] => {
  const currentNavNode = navNodes.get(location.pathname.replaceAll(/^\/|\/$/g, ''));

  if (currentNavNode) {
    if (
      currentNavNode.slug.includes('/reference') ||
      currentNavNode.slug.startsWith('domains') ||
      currentNavNode.slug.startsWith('api-products')
    ) {
      return [defaultItem, { href: `/${currentNavNode.slug}`, title: currentNavNode.title }];
    }

    return createItems(currentNavNode, navNodes);
  }

  return [];
};
