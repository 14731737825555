import {
  Breadcrumb as BreadCrumbWrapper,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'components/ui/breadcrumbs';

export type BreadcrumbItemT = { title: string; href: string };
export type BreadcrumbProps = { items: BreadcrumbItemT[] } & React.HTMLAttributes<HTMLDivElement>;

export const Breadcrumb = ({ items, ...props }: BreadcrumbProps) => {
  if (items.length < 2) return null;

  return (
    <BreadCrumbWrapper data-testid="breadcrumb" {...props}>
      <BreadcrumbList>
        {items.map(({ title, href }, index, array) => (
          <BreadcrumbItem key={`${title}-${href}`} className="text-base my-1">
            {index < array.length - 1 ? (
              <BreadcrumbLink
                data-testid="breadcrumb-link"
                href={href}
                className="hover:underline hover:underline-offset-[3px]"
                title={title}
              >
                {title}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbPage className="text-purple-700 dark:text-purple-300">{title}</BreadcrumbPage>
            )}
            {index < array.length - 1 && <BreadcrumbSeparator />}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </BreadCrumbWrapper>
  );
};
