import type { SearchData } from 'components/feature/search/search-results';
import { navigationContentParse } from 'components/navigation/navigation-content-parse';
import type { UnflattenedNavigationNodes } from 'components/navigation/navigation-utils';
import { useContent, usePageViewLog, useRightNavigation } from 'hooks';
import { type PropsWithChildren, useEffect } from 'react';
import { SEO } from '../components/feature/seo';
import { RootLayout } from './root-layout';

export type PageLayoutProps = PropsWithChildren<Pick<import('gatsby').PageProps, 'location'>> & {
  specNavigation?: UnflattenedNavigationNodes;
  isLargeContent?: boolean;
  isGreyLayout?: boolean;
  title?: string;
  description?: string;
  isUserContent?: boolean;
  commitDate?: number;
  isReference?: boolean;
};

const EXCLUDED_PAGES = ['/', '/solutions-catalogue/', '/tools-and-guides/'];

export const saveInRecentlyViewed = (title: string, url: string) => {
  if (!EXCLUDED_PAGES.includes(url)) {
    const storedData = localStorage.getItem('recently-viewed');
    const recentlyViewedItems = storedData ? JSON.parse(storedData) : [];
    const filteredItems = recentlyViewedItems.filter(
      (item: SearchData) => item.url !== url && item.meta.title !== title,
    );
    const updatedItems = [{ url, meta: { title }, excerpt: '' }, ...filteredItems].slice(0, 4);

    localStorage.setItem('recently-viewed', JSON.stringify(updatedItems));
  }
};

const ContentLayout = ({
  children,
  location,
  isLargeContent,
  isGreyLayout,
  isUserContent,
  commitDate,
}: PageLayoutProps) => {
  const nodes = useContent();
  const navItems = navigationContentParse(nodes);
  const showRightNav = useRightNavigation(location.pathname);

  return (
    <RootLayout
      location={location}
      hasRightNav={showRightNav}
      isLargeContent={isLargeContent}
      isGreyLayout={isGreyLayout}
      navItems={navItems}
      isUserContent={isUserContent}
      commitDate={commitDate}
    >
      {children}
    </RootLayout>
  );
};

export const PageLayout = ({
  children,
  location,
  isLargeContent,
  isGreyLayout,
  title,
  description,
  isUserContent,
  commitDate,
  isReference,
}: PageLayoutProps) => {
  const nodes = useContent();
  const navItems = navigationContentParse(nodes);
  usePageViewLog(title || location.pathname);

  useEffect(() => {
    saveInRecentlyViewed(title as string, location.pathname);
  }, [location.pathname, title]);

  if (isReference) {
    return (
      <RootLayout
        isLargeContent={isLargeContent}
        location={location}
        navItems={navItems}
        isUserContent={isUserContent}
        commitDate={commitDate}
        isGreyLayout
        isReference
      >
        <SEO title={title} description={description} pathname={location.pathname} lang="en" />
        {children}
      </RootLayout>
    );
  }

  // All other pages
  return (
    <ContentLayout
      location={location}
      isLargeContent={isLargeContent}
      isGreyLayout={isGreyLayout}
      title={title}
      description={description}
      isUserContent={isUserContent}
      commitDate={commitDate}
    >
      <SEO title={title} description={description} pathname={location.pathname} lang="en" />
      {children}
    </ContentLayout>
  );
};
