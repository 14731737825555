import { Text } from 'components/typography/text';
import { Link } from 'components/ui/link';
import { v4 as uuidv4 } from 'uuid';
import { useHeaderElements } from '../../hooks';

export type RightNavigationProps = {
  location: Location;
};

export const RightNavigation = ({ location }: RightNavigationProps) => {
  const menuItems = useHeaderElements();
  if (!menuItems || menuItems?.length === 0) return null;

  return (
    <div
      className="top-[7em] flex flex-col items-start gap-4"
      role="navigation"
      aria-label="On this page"
      data-testid="right-navigation"
    >
      <Text className="text-lg font-bold text-neutral-900 dark:text-dark-50">On this page</Text>
      {menuItems.map((item) => (
        <Link
          key={`${item.id}-${uuidv4()}`}
          href={`${location.pathname}${item.href}`}
          variant="secondary"
          className="dark:focus-visible-outline-2 rounded-lg outline-offset-0 outline-white ring-purple-700 ring-offset-2 focus-visible:ring-4 dark:outline-0 dark:focus-visible:ring-2"
        >
          {item.title}
        </Link>
      ))}
    </div>
  );
};
